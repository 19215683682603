import React from 'react'
import LoginButton from './LoginButton'
import { connect } from 'react-redux'
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode'
import { actions } from 'modules/Auth/auth.redux'

function OAuthBar({ config, googleLoginRequest, ...props }) {
  const apiURL = process.env.REACT_APP_DOMAIN
    ? `https://${process.env.REACT_APP_DOMAIN}/api`
    : ''
  if (!config.providers) return null

  const providers = ['twitter', 'google', 'facebook'].filter(key => config.providers[key])

  const signIn = provider => {
    window.open(`${apiURL}/oauth/${provider}`, '_self')
  }
  const responseSuccess = response => {
    if (response.credential) {
      const decoded = jwt_decode(response.credential)
      console.log(decoded)
      const { email, given_name, family_name, googleId, picture } = decoded
      const values = {
        email,
        name: given_name,
        lastName: family_name,
        providerId: response.clientId,
        thumbnail: picture,
        validated: true,
        providerId: googleId
      }
      googleLoginRequest(values)
    }
  }
  const responseFailure = response => {
    console.log(response)
  }


  return (
    <div className='oauth-bar'>
      {providers.map(provider => {
        if (provider !== 'google') {

          return <LoginButton key={provider} provider={provider} onClick={() => signIn(provider)} />
        } else {
          return <div className='login-button'>
            <GoogleLogin
              size='large'
              onSuccess={responseSuccess}
              onFailure={responseFailure}
              alt=''
            />
          </div>
        }
      }
      )}
    </div>
  )
}

const mapStateToProps = ({ auth, configuration }) => ({ auth, config: configuration.public })
const mapDispatchToProps = {
  googleLoginRequest: actions.googleLoginRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(OAuthBar)

import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from 'layouts/Site/Navbar/Navbar'
import Acl from 'components/Acl/Acl'
import { connect } from 'react-redux'
import iconDonadora from './../../assets/images/logoDonadora4.png'

function Menu({ role }) {
  return (
    <div>
      <Navbar admin />
      <div
        className='contain'
        style={{ display: 'flex', alignItems: 'center', height: '100vh' }}>
        <div className='main clearfix mt-5' style={{ display: 'flex', alignItems: 'center' }}>
          <nav id='menu' className='menu w-100'>
            <button
              type='button'
              id='menutoggle'
              className='menutoogle active'
              aria-hidden='true'>
              <i aria-hidden='true' className='icon-menu'></i>
              Menú
            </button>
            <ul>
              <Acl module='campaign' action='read'>
                <li>
                  <Link to='/dashboard/campaign/list'>
                    <span className='img'>
                      <i aria-hidden='true' className='icon-home' />
                    </span>
                    <span>Campañas</span>
                  </Link>
                </li>
              </Acl>

              <Acl module='admin' action='read'>
                <li>
                  <Link to='/dashboard/user-admin/list'>
                    <span className='img'>
                      <i aria-hidden='true' className='icon-verified_user' />
                    </span>
                    <span>Administradores</span>
                  </Link>
                </li>
              </Acl>

              <Acl module='user' action='read'>
                <li>
                  <Link to='/dashboard/user/list'>
                    <span className='img'>
                      <i aria-hidden='true' className='icon-group' />
                    </span>
                    <span>Usuarios</span>
                  </Link>
                </li>
              </Acl>

              {/* <Acl module='donation' action='read'>
                <li>
                  <Link to='/dashboard/donation/list'>
                    <span className='img'>
                      <i aria-hidden='true' className='icon-heart' />
                    </span>
                    <span>Donaciones</span>
                  </Link>
                </li>
              </Acl> */}

              <Acl module='institution' action='read'>
                <li>
                  <Link to='/dashboard/institution/list'>
                    <span className='img'>
                      <i aria-hidden='true' className='icon-home' />
                    </span>
                    <span>Instituciones</span>
                  </Link>
                </li>
              </Acl>

              <Acl module='category' action='read'>
                <li>
                  <Link to='/dashboard/category/list'>
                    <span className='img'>
                      <i aria-hidden='true' className='icon-check' />
                    </span>
                    <span>Categorías</span>
                  </Link>
                </li>
              </Acl>
              <Acl module='pages' action='read'>
                <li>
                  <Link to='/dashboard/pages/list'>
                    <span className='img'>
                      <i aria-hidden='true' className='icon-edit' />
                    </span>
                    <span>Páginas</span>
                  </Link>
                </li>
              </Acl>
              {role === 0 && (
                <li>
                  <Link to='/dashboard/configuration/edit'>
                    <span className='img'>
                      <i aria-hidden='true' className='icon-cog' />
                    </span>
                    <span>Configuración</span>
                  </Link>
                </li>
              )}

              <Acl module='historical' action='read'>
                <li>
                  <Link to='/dashboard/historical/list'>
                    <span className='img'>
                      <i aria-hidden='true' className='icon-timeline' />
                    </span>
                    <span>Histórico</span>
                  </Link>
                </li>
              </Acl>

              {/* <Acl module='donadora' action='read'>
                <li>
                  <Link to='/dashboard/donadora' style={{backgroundColor: '#666ee8'}}>
                    <span className='img'>
                      <i aria-hidden='true' style={{height: '75px', width: '80px' }}>
                        <img src={iconDonadora} style={{ height: '100px', marginTop: '-40px', marginLeft: '-25px' }} />
                      </i>
                    </span>
                    <span>Aprende</span>
                  </Link>
                </li>
              </Acl> */}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => ({ role: auth.user.role })

export default connect(mapStateToProps)(Menu)

import axios from 'axios'
import swal from 'sweetalert'
import jwt_decode from 'jwt-decode'
import { push } from 'connected-react-router'
import { call, put, select, all, takeLatest } from 'redux-saga/effects'
import { isExpire, setAuthToken } from './../../util/auth'
import { actions, types } from './auth.redux'
import { baseURL } from './../../util/helpers'
import { isEmpty } from './../../util/crud'
import { alertActions } from 'modules/Alert/alert.redux'

const concatURL = baseURL('/api/auth/')

/**
 * Login the user to the system.
 * @param   {object}   action.payload Data passed when the action was dispatched
 * @returns {iterator}
 */
export function* loginSaga({ payload }) {
  try {
    const url = concatURL('login')
    const { data } = yield call(axios.post, url, payload)
    const { token } = data
    // Set token on localStorage
    localStorage.setItem('auth_token', token)
    // Set token in axios Header
    setAuthToken(token)
    // Decode token to get user data
    const decoded = jwt_decode(token)
    yield put(actions.loginSuccess(decoded))

    if (decoded.changePassword) {
      yield put(push('/reset-password'))
    }
  } catch (error) {
    yield put(actions.loginFailure(error))
    console.log(error)
  }
}

export function* refreshProfile() {
  try {
    const userId = yield select(state => state.auth.user._id)
    const { data } = yield call(axios.get, `/api/users/${userId}`)
    yield put(actions.refreshProfileSuccess(data))
  } catch (error) {
    yield put(actions.refreshProfileFailure(error))
    console.log(error)
  }
}

export function* updateProfile({ payload }) {
  try {
    const { values } = payload
    const userId = yield select(state => state.auth.user._id)
    const url = `/api/users/${userId}`

    const historical = { cause: 'Actualización', description: 'Actualización del perfil' }
    const data = { payload: values, historical }
    const { data: updatedUser } = yield call(axios.put, url, data)

    yield put(actions.updateProfileSuccess(updatedUser))
    yield put(alertActions.alertMessageSuccess('Perfil actualizado'))
    yield put(push('/'))
  } catch (error) {
    yield put(actions.updateProfileFailure(error))
    console.log(error)
  }
}

/**
 * Logout the user on the system
 * 1 - Remove the token from localstorage
 * 2 - Remove the auth headers
 * 3 - Clean up the store->user redux node
 * @returns {iterator}
 */
export function* logoutSaga() {
  try {
    const decoded = yield jwt_decode(localStorage.getItem('auth_token'))
    const id = decoded._id

    const url = concatURL('logout', id)
    yield call(axios.put, url, { login: false })
    // Remove token from localStorage
    localStorage.removeItem('auth_token')
    // Remove the auth header for future requests
    setAuthToken(false)
    // Unset the user on the redux store
    yield put(actions.logoutSuccess())
  } catch (error) {
    yield put(actions.logoutFailure())
    console.log(error)
  }
}

/**
 * Send the server the new user's password, its called in the user's first login
 * @param   {object}   action.payload Data passed when the action was dispatched
 * @returns {iterator}
 */
export function* resetPasswordSaga({ payload }) {
  try {
    const url = concatURL('reset-password')
    yield call(axios.post, url, payload)
    yield call(
      swal,
      'Contraseña actualizada',
      'Por favor, inicie sesión nuevamente',
      'success'
    )
    // Logout the user for request login using the new password
    yield put(actions.logoutRequest())
    yield put(actions.resetPasswordSuccess())
  } catch (error) {
    yield put(actions.resetPasswordFailure(error))
    console.log(error)
  }
}

/**
 * The user request a change of password
 * @param   {object}   action.payload Data passed when the action was dispatched
 * @returns {iterator}
 */
export function* requestRecoverPasswordSaga({ payload }) {
  try {
    const url = concatURL('request-recover-password')
    yield call(axios.post, url, payload)
    swal('Email enviado', 'Revise su bandeja de entrada', 'success')
    yield put(actions.requestRecoverPasswordSuccess())
    yield put(push('/login'))
  } catch (error) {
    yield put(actions.requestRecoverPasswordFailure(error))
    console.log(error)
  }
}

/**
 * The user enters a new password to set as new credentials
 * @param   {object}   action.payload Data passed when the action was dispatched
 * @returns {iterator}
 */
export function* recoverPasswordSaga({ payload }) {
  try {
    const url = concatURL('recover-password')
    yield call(axios.post, url, payload)
    swal('Contraseña actualizada', '', 'success')
    yield put(push('/login'))
  } catch (error) {
    console.log(error)
  }
}

export function* checkLogin() {
  try {
    const token = localStorage.getItem('auth_token')
    if (token) {
      setAuthToken(token) // Set token to Auth header
      const decoded = jwt_decode(token) // Decode token to get user data
      if (decoded && isExpire(decoded.exp)) {
        return yield put(actions.logoutRequest())
      } else {
        const { data: user } = yield axios.get(`/api/users/${decoded._id}`)
        if (user.active === true && user.role === decoded.role)
          return yield put(actions.loginSuccess(decoded))
      }
    }
    yield call(checkLoginCookies)
  } catch (error) {
    yield put(actions.loginFailure(error))
  }
}

function* checkLoginCookies() {
  const { data } = yield call(axios.get, '/api/oauth/login/check', {
    credentials: 'include',
    headers: { 'Access-Control-Allow-Credentials': true }
  })

  // Set token on local storage and in axios header
  if (!isEmpty(data.token)) {
    localStorage.setItem('auth_token', data.token)
    setAuthToken(data.token)
    return yield put(actions.loginSuccess(data.user))
  }
}

export function* googleLoginSaga({ payload }) {
  try {
    const url = concatURL('google-register-or-login')
    const { data } = yield call(axios.post, url, payload)
    const { token } = data
    // Set token on localStorage
    localStorage.setItem('auth_token', token)
    // Set token in axios Header
    setAuthToken(token)
    // Decode token to get user data
    const decoded = jwt_decode(token) 
    yield put(actions.googleLoginSuccess(decoded))
    yield put(push('/'))
  } catch (error) {
    yield put(actions.googleLoginFailure(error))
    console.log(error)
  }
}

export function* authSagas() {
  yield all([
    takeLatest(types.LOGIN_REQUEST, loginSaga),
    takeLatest(types.LOGOUT_REQUEST, logoutSaga),
    takeLatest(types.RESET_PASSWORD_REQUEST, resetPasswordSaga),
    takeLatest(types.REQUEST_RECOVER_PASSWORD_REQUEST, requestRecoverPasswordSaga),
    takeLatest(types.RECOVER_PASSWORD_REQUEST, recoverPasswordSaga),
    takeLatest(types.CHECK_LOGIN_REQUEST, checkLogin),
    takeLatest(types.UPDATE_PROFILE_REQUEST, updateProfile),
    takeLatest(types.GOOGLE_LOGIN_REQUEST, googleLoginSaga)
  ])
}
